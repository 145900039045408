/* eslint-disable no-extra-semi */
import React, { useMemo } from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'

import { isAuthenticated } from '../contexts/auth'
import { User } from '../typings/user'

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const user = useMemo((): User | undefined => {
    const userFromStorage = localStorage.getItem('user')

    if (!userFromStorage) {
      return undefined
    }

    return JSON.parse(userFromStorage) as User
  }, [])

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (
          (location.pathname === '/my-stores' ||
            location.pathname.includes('admin')) &&
          !user?.roles.includes('ROLES_ADMIN')
        ) {
          return (
            <Redirect
              to={{
                pathname: `/store/${user?.store.id}`,
                state: { from: location },
              }}
            />
          )
        }

        return isAuthenticated() ? (
          children
        ) : (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        )
      }}
    />
  )
}
