import styled from 'styled-components'

export const Wrapper = styled.main`
  width: 100%;
  max-width: 958px;
  margin: auto;
  padding: 32px 0;
`

export const Form = styled.form`
  max-width: 600px;
  padding: 33px 40px;
  border: 1px solid #ededed;
  border-radius: 8px;
`

export const Title = styled.h1`
  margin-bottom: 22px;
  margin-top: 70px;
`

export const Select = styled.select`
  width: 100%;
  padding: 20px 16px;
  border-radius: 8px;
  position: relative;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat calc(100% - 16px) transparent;
  -webkit-appearance: none;
`

export const SelectWrapper = styled.div`
  margin-bottom: 20px;
`

export const SelectLabel = styled.label`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: var(--text-color-500);
`
