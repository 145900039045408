/* eslint-disable no-unused-expressions */
import React from 'react'

import { toast } from 'react-toastify'
import {
  ListPanel,
  PanelHeader,
  PanelInfo,
  PanelTitle,
  Percentage,
  PercentageDescription,
  GiftPurchased,
  PercentageValue,
  Balance,
  BalenceValue,
  Bill,
  BillItem,
  BillDivisor,
  OverBalance,
  OverBalanceValue,
  Text,
  DetailsBtn,
} from './styles'

import Button from '../Button'
import { api } from '../../services/api'

type Props = {
  listId: number
  redemptionCode: string
  balance: number
  expiringDate: string
  totalBoughtPercent: number
}

const Panel: React.FC<Props> = ({
  listId,
  balance,
  expiringDate,
  redemptionCode,
  totalBoughtPercent,
}) => {
  const token = localStorage.getItem('app-token')
  const handleDeleteList = async (Id: number) => {
    try {
      const result = await api.delete(`/user/giftlist/disable/${Id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const err = result.data.errors

      if (typeof err === 'string') {
        toast.error(result.data.message)
      } else {
        result.data.message === 'Lista não encontrata.'
          ? toast.error('Apenas o criador da lista pode desativa-la.')
          : toast.success(result.data.message)
      }
    } catch (err) {
      toast.error('Falha ao desativar.')
    }
  }

  return (
    <>
      <ListPanel>
        <PanelHeader>
          <PanelTitle>Extrato</PanelTitle>
          <Button
            buttonStyle="secondary"
            text="FINALIZAR LISTA"
            isLoading={false}
            onClick={() => handleDeleteList(listId)}
          />{' '}
        </PanelHeader>
        <PanelInfo>
          <Balance>
            SALDO ATUAL:
            <BalenceValue>
              R$
              {balance}
            </BalenceValue>
          </Balance>
          <GiftPurchased>
            <Percentage>
              {totalBoughtPercent}%
              <PercentageDescription>
                dos presentes comprados
              </PercentageDescription>
            </Percentage>
            <PercentageValue>
              R${' '}
              {balance.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
              })}{' '}
              ({totalBoughtPercent}
              %)
            </PercentageValue>
          </GiftPurchased>
          <Bill>
            <BillItem>
              <Text>Produtos:</Text>
              <Text>
                R${' '}
                {balance.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                })}
              </Text>
            </BillItem>
            <BillItem>
              <Text>Vale-presente:</Text>
              <Text>{redemptionCode}</Text>
            </BillItem>
            <BillItem>
              <Text>Data de Validade:</Text>
              <Text>{expiringDate}</Text>
            </BillItem>
            <BillDivisor />
            <BillItem>
              <OverBalance>SALDO:</OverBalance>
              <OverBalanceValue>
                R${' '}
                {balance.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                })}
              </OverBalanceValue>
            </BillItem>
          </Bill>
          <DetailsBtn>Ver Detalhes</DetailsBtn>
        </PanelInfo>
      </ListPanel>
    </>
  )
}

export default Panel
