import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button'
import * as S from './styles'

type Props = {
  title: string
  hrefButton?: string
  createButtonText?: string
  onClick?: () => void
}

const TableHeader: React.FC<Props> = ({
  title,
  hrefButton,
  createButtonText = 'Criar usuário',
  onClick,
}) => {
  return (
    <S.TableHeaderWrapper>
      <h1>{title}</h1>
      {!onClick && hrefButton ? (
        <Link
          to={hrefButton}
          style={{
            textDecoration: 'none',
          }}
        ></Link>
      ) : (
        <Button
          buttonStyle="primary"
          onClick={onClick}
          text={createButtonText}
          style={{
            width: 'max-content',
            display: 'block',
          }}
        />
      )}
    </S.TableHeaderWrapper>
  )
}

export default TableHeader
