import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ErrorContainer = styled.div`
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ErrorMessage = styled.h1``

export const Wrapper = styled.main`
  padding: 32px 0;
  max-width: 958px;
  margin: auto;
  width: 100%;
`
export const ListHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: 20px 0;
  grid-gap: 10px;
  @media (min-width: 550px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 0;
  }
`
export const ListOwners = styled.h4`
  display: flex;
  grid-gap: 20px;
  align-items: center;
`
export const ProfileImgWrapper = styled.div`
  color: white;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  text-align: center;
  border-radius: 50%;
  background: #f3f3f3;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
`
export const ListId = styled.h4`
  margin-top: 30px;
`
export const ProductFilters = styled.div`
  display: flex;
  padding: 16px 32px;
  background-color: #fff;
  grid-gap: 10px;
  margin: 20px 0;
  flex-direction: column;
  align-items: baseline;
  border-radius: 8px;

  @media (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`
export const Count = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #757575;
`
export const CountValue = styled.span`
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* color-1-500 */

  color: var(--yellow-500);
`
export const ListContainer = styled.table`
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;

  td {
    padding: 16px;
    border-top: 2px solid #f5f5f5;
  }
`
export const GoBackBtn = styled(Link)`
  color: var(--text-color-500);
  background-color: white;
  border: 1px solid var(--text-color-500);
  border-radius: 4px;

  font-weight: bold;
  font-size: 12px;
  text-align: center;
  text-decoration: none;

  height: 35px;
  padding: 1px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;

  transition: 0.6s;
  &:hover {
    outline: none;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 15%);
    background-color: var(--text-color-500);
    color: white;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
    height: 40px;
    padding: 0 10px;
    border: 2px solid var(--text-color-500);
  }
`
