import styled from 'styled-components'

export const Container = styled.div<{ modalIsOpen: boolean }>`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  opacity: ${(props) => (props.modalIsOpen ? 1 : 0)};
  visibility: ${(props) => (props.modalIsOpen ? 'visible' : 'hidden')};
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`

export const ModalContent = styled.section`
  position: relative;
  max-width: 400px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px 24px;
  box-shadow: 0px 16px 80px rgba(0, 0, 0, 0.32);
  margin: 10px;
`

export const TextArea = styled.div`
  text-align: center;
  grid-gap: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 20px;
`
export const ConfirmButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  border: none;

  font-family: Inter;
  font-weight: bold;
  font-size: 12px;
  text-align: center;

  height: 35px;
  padding: 1px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;
  @media (min-width: 1200px) {
    font-size: 16px;
    height: 40px;
    padding: 0 10px;
  }

  width: 100%;
  color: white;
  background-color: var(--error-300);

  &:hover {
    background-color: var(--error-500);
  }
`

export const CancelButton = styled(ConfirmButton)`
  cursor: pointer;
  background-color: var(--text-color-200);
  &:hover {
    background-color: #9d9d9d;
  }
`

export const CloseButton = styled.span`
  cursor: pointer;
  padding: 12px;
`

export const Title = styled.h3`
  font-size: 18px;
  line-height: 1.5;
  color: #313131;
  font-weight: 600;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`
