import React, { useEffect, useMemo, useState } from 'react'
import Header from '../../components/Header'
import * as S from './styles'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { api } from '../../services/api'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { User } from '../../typings/user'
import { StoreType } from '../../typings/giftlist'

type ParamsProps = {
  id: string
}

type FormUser = {
  password: string
  confirmPassword: string
  store: string
} & Omit<User, 'id' | 'is_active' | 'store'>

const CreateOrUpdateUser: React.FC = () => {
  const [user, setUser] = useState<User>()
  const [stores, setStores] = useState<StoreType[]>()
  const history = useHistory()
  const { id } = useParams<ParamsProps>()

  const schema = useMemo(() => {
    if (user && Object.keys(user).length > 0) {
      return yup.object().shape({
        name: yup.string().required('User é um campo obrigatório'),
        username: yup.string().required('User Name é um campo obrigatório'),
        roles: yup.string().required('Roles é um campo obrigatório'),
        store: yup.string().required('Account é um campo obrigatório'),
      })
    }

    return yup.object().shape({
      name: yup.string().required('Name é um campo obrigatório'),
      username: yup.string().required('User Name é um campo obrigatório'),
      password: yup.string().required('Password é um campo obrigtório'),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'As senhas deve ser iguais'),
      roles: yup.string().required('Roles é um campo obrigatório'),
      store: yup.string().required('Account é um campo obrigatório'),
    })
  }, [user])

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormUser>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    api
      .get('/admin/store')
      .then((res) => setStores(res.data))
      .catch(() => toast.error('Erro ao buscar lojas'))

    if (id) {
      api
        .get(`/admin/user/${id}`)
        .then((res) => setUser(res.data))
        .catch(() => toast.error('Erro ao buscar usuário'))

      return
    }

    setUser({} as User)
  }, [])

  const createUser = async (values: FormUser) => {
    const payload = {
      ...values,
      roles: [values.roles],
      store: {
        vtex_account_name: values.store,
      },
    }

    try {
      if (id) {
        await api.put(`/admin/user/${id}`, payload)
        history.push(`/admin/users`)
        return
      }

      await api.post('/public/user', payload)
      history.push(`/admin/users`)
    } catch (err) {
      console.log(err)
      toast.error('Erro ao criar loja. Tente novamente mais tarde!')
    }
  }

  if (!user) {
    return <></>
  }

  return (
    <>
      <Header storeName={'Admin'} />
      <S.Wrapper>
        <S.Title>Criar Usuário</S.Title>
        <S.Form onSubmit={handleSubmit(createUser)}>
          <Input
            type="text"
            labelFor="User"
            labelText="User"
            placeholder="Nome do usuário"
            error={errors.name}
            maxWidth={600}
            {...register('name', {
              value: user?.name,
            })}
          />
          <Input
            type="text"
            labelFor="User name"
            labelText="User name"
            placeholder="maeztradmin"
            maxWidth={600}
            error={errors.username}
            {...register('username', { value: user?.username })}
          />
          {Object.keys(user).length === 0 && (
            <>
              <Input
                type="password"
                labelFor="Password"
                labelText="Password"
                placeholder=""
                maxWidth={600}
                error={errors.password}
                canBeVisible
                {...register('password')}
              />
              <Input
                type="password"
                labelFor="Confirm Password"
                labelText="Confirm Password"
                placeholder=""
                maxWidth={600}
                canBeVisible
                error={errors.confirmPassword}
                {...register('confirmPassword')}
              />
            </>
          )}
          <S.SelectWrapper>
            <S.SelectLabel htmlFor="store">Account</S.SelectLabel>
            <S.Select
              {...register('store')}
              defaultValue={user?.store?.vtex_account_name}
            >
              {stores &&
                stores?.map((store) => (
                  <option key={store.id} value={store.vtex_account_name}>
                    {store.vtex_account_name}
                  </option>
                ))}
            </S.Select>
          </S.SelectWrapper>
          <S.SelectWrapper>
            <S.SelectLabel htmlFor="roles">Role</S.SelectLabel>
            <S.Select
              {...register('roles')}
              defaultValue={user?.roles ? user.roles[0] : 'ROLES_ADMIN'}
            >
              <option value="ROLES_ADMIN">Admin</option>
              <option value="ROLES_STORE">Store</option>
            </S.Select>
          </S.SelectWrapper>
          <Button
            text="Salvar"
            buttonStyle="primary"
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          />
        </S.Form>
      </S.Wrapper>
    </>
  )
}

export default CreateOrUpdateUser
