import styled from 'styled-components'

export const StyledInput = styled.input<{
  hasError: boolean
}>`
  outline: none;
  border: ${(props) =>
    props.hasError
      ? '1px solid var(--error-500)'
      : '1px solid var(--text-color-100)'};
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color-300);
  border-radius: 6px;
  padding: 8px 16px;
  width: 100%;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
  &::placeholder {
    font-family: Inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    color: var(--text-color-200);
  }
`

export const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: var(--text-color-500);
`
export const ErrorMessage = styled.span`
  position: relative;
  color: var(--error-500);
  bottom: 20px;
  font-size: 14px;
`

export const InputWrapper = styled.div<{
  maxWidth: number
}>`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`

export const ChangeTypeButton = styled.button`
  position: absolute;
  right: 20px;
  transform: translateY(-50%);
  top: 50%;
  background-color: transparent;
  border: none;
  outline: 0;

  &:active,
  &:focus,
  &:hover {
    outline: 0;
    border: none;
  }
`
