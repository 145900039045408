import styled from 'styled-components'

export const TitleWrapper = styled.div<{ as: string }>`
  width: 100%;
  text-align: left;
  font-size: 24px;

  ${(props) => `
    ${props.as} {
      margin: 40px 0;
    }
  `}
`
