/* eslint-disable no-octal-escape */
import React, { ButtonHTMLAttributes, ReactElement } from 'react'

import { StyledButton } from './styles'

export type ButtonStyles = 'primary' | 'secondary' | 'transparent' | 'danger'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  buttonStyle: ButtonStyles
  text: string
  icon?: ReactElement
  isLoading?: boolean
}

const Button: React.FC<ButtonProps> = ({
  buttonStyle,
  text,
  icon,
  isLoading,
  ...rest
}) => (
  <StyledButton buttonStyle={buttonStyle} {...rest}>
    {icon}
    {isLoading ? 'Carregando...' : text}
  </StyledButton>
)

export default Button
