import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components'

export const SearchInput = styled.input`
  outline: none;
  border: 1px solid var(--text-color-100);
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color-300);
  border-radius: 6px;
  padding: 20px 16px;
  width: 100%;
  max-width: 400px;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
  &::placeholder {
    font-family: Inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    display: flex;
    align-items: center;
    color: var(--text-color-200);
  }
`
export const Group = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
`
export const Label = styled.label`
  font-size: 14px;
  top: 20px;
  left: 19px;
  color: var(--text-color-200);

  font-weight: normal;
  pointer-events: none;

  position: absolute;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
`

export const Ipt = styled.input`
  display: block;
  width: 100%;
  max-width: 400px;
  padding: 8px 16px;

  outline: none;
  border: 1px solid var(--text-color-100);
  border-radius: 6px;

  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color-300);

  &:focus {
    outline: none;
  }
`
export const Icon = styled(FaSearch)`
  position: absolute;
  font-size: 14px;
  top: 11px;
  right: 19px;
  color: var(--text-color-200);
`
