import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import * as S from './styles'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { api } from '../../services/api'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { StoreType } from '../../typings/giftlist'
import { yupResolver } from '@hookform/resolvers/yup'

type ParamsProps = {
  id: string
}

type FormStore = Omit<StoreType, 'id' | 'is_active'>

const schema = yup.object().shape({
  vtex_account_name: yup
    .string()
    .required('Account name é um campo obrigatório'),
  vtex_key: yup.string().required('Vtex Key é um campo obrigatório'),
  vtex_token: yup.string().required('Vtex Token é um campo obrigtório'),
})

const CreateOrUpdateStore: React.FC = () => {
  const [store, setStore] = useState<StoreType>()
  const history = useHistory()
  const { id } = useParams<ParamsProps>()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormStore>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (id) {
      api
        .get(`/admin/store/${id}`)
        .then((res) => setStore(res.data))
        .catch(() => toast.error('Erro ao buscar loja'))
      return
    }

    setStore({} as StoreType)
  }, [])

  const createStore = async (values: FormStore) => {
    try {
      if (id) {
        await api.put(`/admin/store/${id}`, values)
        history.push(`/admin/stores`)
        return
      }

      await api.post('/admin/store', values)
      history.push(`/admin/stores`)
    } catch (err) {
      console.log(err)
      toast.error('Erro ao criar loja. Tente novamente mais tarde!')
    }
  }

  if (!store) {
    return <></>
  }

  return (
    <>
      <Header storeName={'Admin'} />
      <S.Wrapper>
        <S.Title>Criar Lista</S.Title>
        <S.Form onSubmit={handleSubmit(createStore)}>
          <Input
            type="text"
            labelFor="Account Name"
            labelText="Account Name"
            placeholder="maeztraio"
            error={errors.vtex_account_name}
            {...register('vtex_account_name', {
              value: store?.vtex_account_name,
            })}
          />
          <Input
            type="text"
            labelFor="Vtex Key"
            labelText="Vtex Key"
            placeholder="vtexappkey-maeztra-LHOTPS"
            error={errors.vtex_key}
            {...register('vtex_key', { value: store?.vtex_key })}
          />
          <Input
            type="text"
            labelFor="Vtex Token"
            labelText="Vtex Token"
            placeholder="RVIWREOMCCVPIEAMWXOOOJFOKWV..."
            error={errors.vtex_token}
            {...register('vtex_token', { value: store?.vtex_token })}
          />
          <Button
            text="Salvar"
            buttonStyle="primary"
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          />
        </S.Form>
      </S.Wrapper>
    </>
  )
}

export default CreateOrUpdateStore
