import styled from 'styled-components'

export const ListPanel = styled.div`
  border: 2px solid var(--text-color-100);
`
export const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
`
export const PanelInfo = styled.div`
  display: grid;
  margin: 10px;
  grid-template:
    'b a'
    'd d'
    'c c';
  align-items: center;
  @media (min-width: 600px) {
    grid-gap: 40px;
    grid-template:
      'a a a'
      'b c c';
    grid-template-columns: 120px 1fr;
    align-items: auto;
  }
`
export const PanelTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #333333;
`
export const Balance = styled.h3`
  font-weight: bold;
  font-size: 18px;
  color: var(--yellow-500);
  display: flex;
  margin: 10px;
  grid-area: a;
  flex-direction: column;
  grid-gap: 10px;
  @media (min-width: 600px) {
    flex-direction: row;
    grid-gap: 30px;
  }
`
export const BalenceValue = styled.h3`
  font-weight: bold;
  font-size: 18px;
  color: #333333;
`
export const GiftPurchased = styled.div`
  grid-area: b;
`
export const Percentage = styled.div`
  font-weight: bold;
  font-size: 24px;

  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  border: 3px solid var(--yellow-500);
  border-radius: 50%;
  width: 120px;
  height: 120px;
`
export const PercentageDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  color: #000000;
`
export const PercentageValue = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  text-align: center;
  margin: 10px auto;
`
export const DetailsBtn = styled.button`
  grid-area: d;
  background: transparent;
  border: none;
  outline: none;
  font-family: Inter;
  color: var(--text-color-500);
  font-size: 12px;
  margin-right: auto;
  margin-left: 10px;
  cursor: pointer;
  @media (min-width: 600px) {
    display: none;
  }
  &:hover {
    color: var(--text-color-300);
  }
`
export const Bill = styled.div`
  display: none;
  grid-area: c;
  @media (min-width: 600px) {
    display: block;
  }
  ${DetailsBtn}:focus ~ & {
    display: block;
    margin: 10px;
    border: 1px solid var(--text-color-100);
    padding: 10px;
  }
`
export const BillItem = styled.div`
  justify-content: space-between;
  display: flex;
  margin: 5px 0;
`
export const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #000000;
`
export const BillDivisor = styled.div`
  width: 100%;
  border: 1px solid #555555;
  margin: 10px auto;
`
export const OverBalance = styled.b`
  font-weight: bold;
  font-size: 16px;
  color: var(--text-color-300);
`
export const OverBalanceValue = styled.b`
  font-weight: bold;
  font-size: 16px;
  color: #000000;
`
