import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  border: 1px solid #ededed;
  border-radius: 4px;
  margin: 20px 10px;
  padding: 15px;

  display: grid;
  grid-template-areas:
    'a a'
    'b b'
    'c d';
  align-items: center;

  @media (min-width: 768px) {
    grid-template-areas: 'a c b d';
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }
`
export const Div = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 10px;
  grid-area: a;
`
export const Img = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;
`
export const ProductName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  /* identical to box height */

  color: #000000;
`
export const ProductLink = styled(Link)`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
  color: var(--yellow-500);
`

export const ProductStatus = styled.b`
  color: green;
  text-align: center;
`

export const Quantity = styled.form`
  background: #efefef;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin: auto;
  grid-area: c;
`

export const InputNumber = styled.input`
  width: 32px;
  height: 32px;
  background: #efefef;
  outline: none;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #666666;
  border: none;
  padding-left: 10px;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const ValueBtn = styled.button`
  border: none;
  color: #666666;
  margin: 0 10px;
  cursor: pointer;
`

export const Price = styled.div`
  font-weight: bold;
  font-size: 14px;

  display: flex;
  flex-direction: column;
  margin-left: 74px;
  margin-bottom: 10px;
  grid-area: b;
  @media (min-width: 600px) {
    text-align: center;
    margin: auto;
  }
`

export const PreviousPrice = styled.span`
  font-size: 12px;
  text-decoration-line: line-through;
  color: var(--text-color-200);
  @media (min-width: 600px) {
    text-align: center;
  }
`
