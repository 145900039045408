/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
// import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'

import { api } from '../../services/api'

import { ErrorContainer, ErrorMessage, Container, Store } from './styles'
import Header from '../../components/Header'

type storeParams = {
  id: number
  vtex_account_name: string
}

const MyStores: React.FC = () => {
  const [stores, setStores] = useState<storeParams[]>([])
  const [error, setError] = useState('')

  const token = localStorage.getItem('app-token')
  const user = localStorage.getItem('user')
  const userData = user !== null ? JSON.parse(user) : {}

  const loadStores = async () => {
    try {
      const response = await api.get('/admin/store', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.data) {
        setError('')
        setStores(response.data)
      } else {
        setError('Nenhuma loja cadastrada!')
      }
      // }
    } catch (err) {
      setError('Falha ao carregar as lojas.')
    }
  }

  useEffect(() => {
    loadStores()
  }, [])

  return (
    <>
      <Header storeName="Admin" />
      {error ? (
        <ErrorContainer>
          <ErrorMessage>{error}</ErrorMessage>
        </ErrorContainer>
      ) : (
        <Container>
          {stores
            .filter((store: storeParams) => {
              if (userData.roles.find((e: string) => e === 'ROLES_STORE')) {
                if (store.id === userData.store) {
                  return store
                }
              } else {
                return store
              }
            })
            .map((store: storeParams) => (
              <Store key={store.id} to={{ pathname: `/store/${store.id}` }}>
                {' '}
                {store.vtex_account_name}
              </Store>
            ))}
        </Container>
      )}
    </>
  )
}
export default MyStores
