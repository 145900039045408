/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import DataTable, { TableProps, TableStyles } from 'react-data-table-component'

const customStyles: TableStyles = {
  rows: {
    style: {
      borderBottom: '1px solid rgba(0, 0, 0, .12)',
      borderRight: '1px solid rgba(0, 0, 0, .12)',
      borderLeft: '1px solid rgba(0, 0, 0, .12)',
      padding: '8px 24px',
      '&:last-child': {
        borderRadius: '0 0 8px 8px',
      },
    },
  },
  cells: {
    style: {
      padding: '0',
      height: 'auto',
      fontWeight: '700',
    },
  },
  headRow: {
    style: {
      backgroundColor: '#EDEDED',
      borderBottom: 'none',
      borderRadius: '8px 8px 0 0',
      padding: '12px 24px',
    },
  },
  headCells: {
    style: {
      padding: '0',
      height: 'auto',
      fontSize: '16px',
      color: '#555555',
      fontWeight: '700',
    },
  },
}

function Table<T>(props: TableProps<T>) {
  return <DataTable {...props} fixedHeader={true} customStyles={customStyles} />
}

export default Table
