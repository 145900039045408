import React from 'react'
import * as S from './styles'

type TitleProps = {
  as?: React.ElementType
}

const Title: React.FC<TitleProps> = ({ children, as = 'h1' }) => {
  return <S.TitleWrapper as={as}>{[children]}</S.TitleWrapper>
}

export default Title
