import styled from 'styled-components'

export const Wrapper = styled.main`
  width: 100%;
  max-width: 958px;
  margin: auto;
  padding: 32px 0;
`

export const StyledImageUpload = styled.div`
  margin-bottom: 14px;

  input[type='file' i] {
    display: none;
  }

  label {
    background-color: var(--yellow-500);
    border-radius: 4px;
    font-family: Inter;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
  }

  img {
    max-width: 100%;
    border-radius: 14px;
    margin-top: 18px;
  }
`
