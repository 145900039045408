/* eslint-disable no-nested-ternary */
import styled from 'styled-components'
import { ButtonStyles } from '.'

export const StyledButton = styled.button<{
  buttonStyle: ButtonStyles
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: Inter;
  cursor: pointer;
  ${(props) =>
    props.buttonStyle === 'primary' &&
    `
    border: 1px solid #181818;
    color: white;
    background-color: #181818;

    &:hover {
      background-colot: #494949;
    }
  `}

  ${(props) =>
    props.buttonStyle === 'secondary' &&
    `
    color: #313131;
    background-color: white; 
    border: 1px solid #E4E4E4; 

    &:hover {
      border: 1px solid #CACACA;
      background: #E4E4E4;
    }
  `}

  ${(props) =>
    props.buttonStyle === 'transparent' &&
    `
    color: #313131;
    background-color: white;
    border: none;
  `}

  ${(props) =>
    props.buttonStyle === 'danger' &&
    `
    color: #F33520;
    background-color: white;
    border: none;
  `}

  transition: 0.6s;
  &:hover {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
`
