import React, { InputHTMLAttributes } from 'react'

import { Group, Ipt, Icon } from './styles'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  placeholder: string
}

const Search: React.FC<Props> = ({ placeholder, ...rest }) => {
  return (
    <>
      <Group>
        <Ipt type="text" {...rest} placeholder={placeholder} />
        <Icon />
      </Group>
    </>
  )
}

export default Search
