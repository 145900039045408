import styled from 'styled-components'

export const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  height: 100vh;
  padding: 32px 8px;
  background-color: #fff;
  max-width: 250px;
  width: 100%;
  text-align: center;
`

export const TitleContainer = styled.div`
  padding: 20px 0;
  background-color: var(--text-color-100);
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  color: var(--text-color-500);
  font-size: 18px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
`
export const Logo = styled.img`
  max-width: 100%;
  margin: auto;
  margin-bottom: 72px;
`
export const NavItem = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  color: var(--text-color-500);
  margin: auto 1vw;

  a {
    margin-left: 8px;
    text-decoration: none;
    display: block;
    color: inherit;
  }
`
export const NavLeft = styled.nav`
  display: flex;
  ${NavItem} {
    display: none;
  }
  @media (min-width: 865px) {
    ${NavItem} {
      display: flex;
    }
  }
`
export const NavRight = styled.nav`
  display: flex;
`
export const Icon = styled.div`
  font-size: 18px;
  color: var(--text-color-500);
`
