import React, {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useState,
} from 'react'
import { FieldError } from 'react-hook-form'

import * as S from './styles'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  labelFor: string
  labelText: string
  placeholder: string
  error?: FieldError
  type: string
  maxWidth?: number
  canBeVisible?: boolean
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  {
    labelFor,
    labelText,
    placeholder,
    error,
    type,
    maxWidth = 400,
    canBeVisible = false,
    ...rest
  },
  ref
) => {
  const [inputType, setInputType] = useState('password')
  const handleButton = () => {
    setInputType((type) => (type === 'password' ? 'text' : 'password'))
  }
  return (
    <div style={rest.style}>
      <S.Label htmlFor={labelFor}>{labelText}</S.Label>
      <S.InputWrapper maxWidth={maxWidth}>
        <S.StyledInput
          type={canBeVisible ? inputType : type}
          id={labelFor}
          placeholder={placeholder}
          ref={ref}
          {...rest}
          hasError={!!error}
        />
        {canBeVisible && (
          <S.ChangeTypeButton onClick={() => handleButton()}>
            {inputType === 'password' ? (
              <FaEye color="#acadad" />
            ) : (
              <FaEyeSlash color="#acadad" />
            )}
          </S.ChangeTypeButton>
        )}
      </S.InputWrapper>
      {!!error && <S.ErrorMessage>{error.message}</S.ErrorMessage>}
    </div>
  )
}

const Input = forwardRef(InputBase)

export default Input
