/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FaUserAlt } from 'react-icons/fa'
import { toast } from 'react-toastify'

import ProgressBar from '../../components/ProgressBar'
import Header from '../../components/Header'
import Product from '../../components/Product'
// import DropDown from '../../components/Select'
// import Search from '../../components/Search'
import Panel from '../../components/Panel'

import { api } from '../../services/api'
import { GiftCardType, GiftlistType, ProductType } from '../../typings/giftlist'

import {
  ErrorContainer,
  ErrorMessage,
  Wrapper,
  ListId,
  ListHeader,
  ListOwners,
  ProfileImgWrapper,
  ProductFilters,
  Count,
  CountValue,
  ListContainer,
  GoBackBtn,
} from './styles'
import useStore from '../../hooks/useStore'
import { Div } from '../../components/Product/styles'

type ParamsProps = {
  id: string
}

const Giftlist: React.FC = () => {
  const { id } = useParams<ParamsProps>()
  const [list, setList] = useState<GiftlistType>()
  const [skus, setSkus] = useState<any[]>([])
  const [balance, setBalance] = useState<GiftCardType>()
  const [error, setError] = useState('')
  // const [searchTerm, setSearchTerm] = useState('')
  // const [orderBy, setOrderBy] = useState('')
  const store = useStore()

  const token = localStorage.getItem('app-token')
  const user = localStorage.getItem('user')
  const userData = user !== null ? JSON.parse(user) : {}

  const loadList = async () => {
    try {
      const response = await api.get(`/user/giftlist/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const { data } = response
      if (data) {
        userData.roles.find((e: string) => e === 'ROLES_STORE') &&
        response.data.store.id !== store?.id
          ? setError('Acesso não autorizado')
          : setError('')

        setList(data)
      } else {
        setError('Nenhuma lista foi encontrada!')
      }
    } catch (err) {
      setError('Falha ao carregar a lista.')
    }
  }

  const loadSkus = async () => {
    try {
      const vtexIds = list?.products.map(
        (product: { vtex_id: string; quantity: number }) => {
          return {
            sku: parseInt(product.vtex_id, 10),
            quantity: product.quantity,
          }
        }
      )
      const ids = vtexIds?.map((id) => id.sku)
      const response = await api.get(`/store/giftlist/${id}/sku/${ids}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.data) {
        const res = response.data
        console.log(res)
        const result = res.filter((sku: ProductType) => sku !== null)
        console.log('result')
        console.log(result)
        setSkus(result)
      }
    } catch (err) {
      toast.error('Falha ao carregar os produtos.')
    }
  }

  const loadBalance = async () => {
    try {
      const response = await api.get(`/user/giftlist/${id}/giftcard`)
      if (response.data) {
        setBalance(response.data)
      }
    } catch (err) {
      toast.error('Falha ao carregar o saldo.')
    }
  }

  // const handleOrderBy = (newValue: { label: string; value: string }) => {
  //   setOrderBy(newValue.value)
  // }

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchTerm(e.target.value)
  // }

  const handleDelete = async (Id: number, VtexId: string) => {
    try {
      const result = await api.delete(
        `/store/giftlist/${Id}/product/remove/${VtexId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const err = result.data.errors

      if (typeof err === 'string') {
        toast.error(result.data.message)
      } else {
        toast.success(result.data.message)
        loadList()
        loadSkus()
      }
    } catch (err) {
      toast.error('Falha ao excluir.')
    }
  }

  useEffect(() => {
    loadList()
  }, [])

  useEffect(() => {
    console.log(list)
    if (list) {
      loadBalance()
    }
  }, [list])

  useEffect(() => {
    if (store && list) {
      loadSkus()
    }
  }, [store, list])

  return (
    <>
      {error ? (
        <>
          <ProgressBar value="100" title="" subtitle="" />
          <ErrorContainer>
            <ErrorMessage>{error}</ErrorMessage>
          </ErrorContainer>
        </>
      ) : (
        <>
          <Header
            storeName={
              list?.store?.vtex_account_name
                ? list.store.vtex_account_name
                : 'Loja'
            }
          />
          <Wrapper>
            <ListHeader>
              <ListOwners>
                <ProfileImgWrapper>
                  <FaUserAlt />
                </ProfileImgWrapper>{' '}
                <Div>
                  <Div>{list?.owner}</Div>
                </Div>
              </ListOwners>
              <GoBackBtn to={`/store/${list?.store.id}`}>VOLTAR</GoBackBtn>
            </ListHeader>
            <Div>
              <strong> Data de expiração: </strong>

              {list?.event_date
                ? new Date(
                    new Date(list.event_date).setMonth(
                      new Date(list.event_date).getMonth() + 1
                    )
                  ).toLocaleDateString('pt-BR')
                : 'Data inválida'}
            </Div>
            <Div>
              <strong>Data de criação: </strong>
              {new Date(list?.created_at ?? '').toLocaleDateString('pt-BR')}
            </Div>
            {list && list?.store?.id === 16 && (
              <Div>
                <a
                  href={`https://www.polishop.com.br/lista-de-presentes/${list?.path}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#000',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  Link para visualizar a lista
                </a>
              </Div>
            )}
            <Panel
              listId={list ? list.id : 0}
              balance={balance?.balance || 0}
              redemptionCode={balance?.redemptionCode || '0000-0000-0000-0000'}
              expiringDate={balance?.expiringDate || 'dd/mm/aaa'}
              totalBoughtPercent={balance?.totalBoughtPercent || 0}
            />
            <ListId>Lista Nº: {list?.id}</ListId>
            <ProductFilters>
              <Count>
                Produtos encontrados:
                <CountValue>
                  &nbsp;
                  {skus.length}
                </CountValue>
              </Count>
              {/* <DropDown
                label="Ordenar por:"
                labelFor="orderBy"
                options={[
                  { label: 'Ordem Padrão', value: 'default' },
                  { label: 'Menor Preço', value: 'lowestPrice' },
                  { label: 'Maior Preço', value: 'highestPrice' },
                ]}
                handleChange={handleOrderBy}
              />
              <Search placeholder="Pesquisar" onChange={handleChange} /> */}
            </ProductFilters>
            <ListContainer>
              {skus
                // .filter((product) => {
                //   if (searchTerm === '') {
                //     return product
                //   }
                //   const productName = product.ProductName
                //   if (
                //     productName.toLowerCase().includes(searchTerm.toLowerCase())
                //   ) {
                //     return product
                //   }
                // })
                .filter(() => {
                  // if (orderBy === 'lowestPrice') {
                  //   const orderSkus = skus?.sort((a, b) => {
                  //     return a.skus[0].bestPrice - b.skus[0].bestPrice
                  //   })
                  //   return orderSkus
                  // }
                  // if (orderBy === 'highestPrice') {
                  //   const orders = skus?.sort((a, b) => {
                  //     return b.skus[0].bestPrice - a.skus[0].bestPrice
                  //   })
                  //   return orders
                  // }

                  const defaultOrder = skus?.sort((a, b) => {
                    return parseInt(a.skuId, 10) - parseInt(b.skuId, 10)
                  })

                  return defaultOrder
                })
                .filter((product) => {
                  //remove empty products
                  return product !== ''
                })
                .map((product) => (
                  <Product
                    key={Math.random()}
                    listId={list ? list.id : 0}
                    product={product}
                    handleDelete={handleDelete}
                  />
                ))}
            </ListContainer>
          </Wrapper>
        </>
      )}
    </>
  )
}
export default Giftlist
