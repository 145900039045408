import styled from 'styled-components'

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 16px;
  max-width: 640px;
  width: 100%;
  margin: 80px auto;
  padding: 40px;
  @media (max-width: 400px) {
    margin: 10px 20px;
  }
`

export const LoginTitle = styled.h2`
  font-size: 24px;
  line-height: 27px;
  color: #313131;
  font-weight: 600;
  margin: 0;
`
