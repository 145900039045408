import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { PrivateRoute } from './PrivateRoute'

import Login from '../pages/Login'
import MyStores from '../pages/MyStores'
import Giftlist from '../pages/Giftlist'
import Store from '../pages/Store'
import Types from '../pages/Types'
import CreateType from '../pages/CreateType'
import AdminStores from '../pages/AdminStores'
import CreateOrUpdateStore from '../pages/CreateOrUpdateStore'
import AdminUsers from '../pages/AdminUsers'
import CreateOrUpdateUser from '../pages/CreateOrUpdateUser'
import Configuration from '../pages/Configuration'

const Routes: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={Login} exact />
      <PrivateRoute exact path="/my-stores">
        <MyStores />
      </PrivateRoute>
      <PrivateRoute exact path="/store/:id/create-type">
        <CreateType />
      </PrivateRoute>
      <PrivateRoute exact path="/store/:id/types">
        <Types />
      </PrivateRoute>
      <PrivateRoute exact path="/store/:id">
        <Store />
      </PrivateRoute>
      <PrivateRoute exact path="/giftlist/:id">
        <Giftlist />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/stores/update/:id">
        <CreateOrUpdateStore />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/user/update/:id">
        <CreateOrUpdateUser />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/stores/create">
        <CreateOrUpdateStore />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/users/create">
        <CreateOrUpdateUser />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/stores">
        <AdminStores />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/users">
        <AdminUsers />
      </PrivateRoute>
      <PrivateRoute exact path="/configuration/:id">
        <Configuration />
      </PrivateRoute>
      <PrivateRoute path="/">404</PrivateRoute>
    </Switch>
  </BrowserRouter>
)

export default Routes
