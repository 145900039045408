/* eslint-disable no-console */
import React from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { toast } from 'react-toastify'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { FormWrapper, LoginTitle } from './styles'

import { api } from '../../services/api'
import LogoImg from '../../assets/images/logo-placeholder.png'

type LoginFormData = {
  username: string
  password: string
}

const LoginFormSchema = yup.object().shape({
  username: yup.string().required('Username é um campo obrigatório'),
  password: yup.string().required('Senha é um campo obrigatório'),
})

const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormData>({
    resolver: yupResolver(LoginFormSchema),
  })

  const handleLogin: SubmitHandler<LoginFormData> = async (values) => {
    const body = {
      username: values.username,
      password: values.password,
    }
    try {
      const { data } = await api.post('/auth/login', body)

      localStorage.setItem('app-token', data.access_token)

      const { user } = data

      localStorage.setItem('user', JSON.stringify(user))

      window.location.href = '/my-stores'
    } catch (error) {
      const e = error as any
      console.log({ ...e })
      console.error(e)
      if (e.response) {
        const { status } = e.response
        if (status === 401 || status === 400)
          toast.error('Email ou senha incorretos!')
      } else {
        toast.error('Erro interno de servidor.')
      }
    }
  }

  return (
    <>
      <FormWrapper onSubmit={handleSubmit(handleLogin)}>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <img src={LogoImg} />
        </div>
        <LoginTitle>Acessar plataforma</LoginTitle>
        <Input
          labelFor="username"
          type="text"
          labelText="Informe seu usuário"
          placeholder="Digite seu Nome"
          style={{ width: '100%' }}
          error={errors.username}
          {...register('username')}
        />
        <Input
          labelFor="password"
          type="password"
          labelText="Informe sua senha"
          placeholder="Digite sua Senha"
          style={{ width: '100%' }}
          error={errors.password}
          {...register('password')}
        />
        <div style={{ textAlign: 'right', width: '100%' }}>
          <Button
            style={{ marginLeft: 'auto' }}
            buttonStyle="primary"
            text="Acessar plataforma"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          />
        </div>
      </FormWrapper>
    </>
  )
}
export default Login
