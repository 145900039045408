import React, { useRef, useState } from 'react'
import Header from '../../components/Header'
import useStore from '../../hooks/useStore'
import * as S from './styles'
import Title from '../../components/Title'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { api } from '../../services/api'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

const CreateType: React.FC = () => {
  const ref = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState<File>()
  const imageUrl = selectedImage ? URL.createObjectURL(selectedImage) : null
  const store = useStore()
  const history = useHistory()

  const isfalse = false
  if (isfalse) {
    setIsLoading(false)
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setSelectedImage(file)
      }
      reader.readAsDataURL(file)
    }
  }

  const createType = async () => {
    setIsLoading(true)
    const typeValue = ref.current?.value

    if (!typeValue) {
      toast.error('Digite o tipo da lista')
      setIsLoading(false)
      return
    }

    try {
      const formData = new FormData()
      formData.append('type', typeValue)

      if (selectedImage) {
        formData.append('thumbnail', selectedImage)
      }

      await api.post('/store/giftlist/types', formData)

      history.push(`/store/${store?.id}/types`)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
      imageUrl && URL.revokeObjectURL(imageUrl)
    }
  }

  return (
    <>
      <Header storeName={store?.vtex_account_name ?? 'Loja'} />
      <S.Wrapper>
        <Title>Criar tipo</Title>
        <div style={{ maxWidth: '400px', margin: 'auto' }}>
          <Input
            ref={ref}
            type="text"
            labelFor="Tipo"
            labelText="Tipo"
            placeholder="Lista de casamento"
          />
          <S.StyledImageUpload>
            <input
              type="file"
              accept="image/*"
              id="imageInput"
              onChange={handleImageChange}
            />
            <label htmlFor="imageInput">Selecionar imagem</label>
            {imageUrl && <img src={imageUrl} alt="Selected" />}
          </S.StyledImageUpload>
          <Button
            text="Salvar"
            buttonStyle="primary"
            onClick={() => createType()}
            isLoading={isLoading}
          />
        </div>
      </S.Wrapper>
    </>
  )
}

export default CreateType
