import React from 'react'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Routes from './routes'
import GlobalStyles from './styles/global'
import { QueryClientProvider, QueryClient } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App: React.FC = () => (
  <>
    <QueryClientProvider client={queryClient}>
      <Routes />
      <GlobalStyles />
      <ToastContainer />
    </QueryClientProvider>
  </>
)

export default App
