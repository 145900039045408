import { useMemo } from 'react'
import { Store, User } from '../typings/user'

const useStore = (): Store => {
  const storeMemo = useMemo((): Store => {
    const userFromStorage = localStorage.getItem('user')

    if (!userFromStorage) {
      return {} as Store
    }

    const { store } = JSON.parse(userFromStorage) as User

    return store
  }, [])

  return storeMemo
}

export default useStore
