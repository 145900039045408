/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import Header from '../../components/Header'
import { api } from '../../services/api'
import { TableColumn, TableStyles } from 'react-data-table-component'
import * as S from './styles'
import Button from '../../components/Button'
import { StoreType } from '../../typings/giftlist'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Table from '../../components/Table'
import TableHeader from '../../components/TableHeader'

const customStyles: TableStyles = {
  header: {
    style: {
      minHeight: '56px',
    },
  },
  rows: {
    style: {
      borderBottom: '1px solid rgba(0, 0, 0, .12)',
      borderRight: '1px solid rgba(0, 0, 0, .12)',
      borderLeft: '1px solid rgba(0, 0, 0, .12)',
    },
  },
}

const AdminStores: React.FC = () => {
  const [types, setTypes] = useState<StoreType[]>()
  const [reqTrigger, setReqTrigger] = useState(false)

  const changeStatus = async (id: number, status: number) => {
    const newStatus = !status ? 'enable' : 'disable'
    const requestVerb = {
      enable: api.put,
      disable: api.delete,
    }
    try {
      await requestVerb[newStatus](`/admin/store/${newStatus}/${id}`)
      setReqTrigger(!reqTrigger)
    } catch (err) {
      console.log(err)
      toast.error('Houve um erro ao desabilitar a loja')
    }
  }

  const columns: TableColumn<StoreType>[] = useMemo(() => {
    return [
      {
        name: 'Id',
        selector: (row) => row.id,
      },
      {
        name: 'Account name',
        selector: (row) => row.vtex_account_name,
      },
      {
        cell: (row) => (
          <>
            <Link
              to={`/admin/stores/update/${row.id}`}
              style={{
                display: 'block',
                textDecoration: 'none',
                width: 'fit-content',
                marginRight: '8px',
              }}
            >
              <Button text="Editar" buttonStyle="secondary" />
            </Link>
            <Button
              buttonStyle={!row.is_active ? 'secondary' : 'danger'}
              text={!row.is_active ? 'Ativar' : 'Inativar'}
              onClick={() => changeStatus(row.id, row.is_active)}
              style={{ width: '90px' }}
            />
          </>
        ),
        allowOverflow: true,
        button: true,
        width: '200px',
      },
    ]
  }, [types])

  useEffect(() => {
    api
      .get<StoreType[]>(`/admin/store`)
      .then((res) => setTypes(res.data))
      .catch(() => toast.error('Erro ao buscar os tipos de lista'))
  }, [reqTrigger])

  return (
    <>
      <Header storeName="Admin" />
      <S.Wrapper>
        <TableHeader title="Lojas" hrefButton="/admin/stores/create" />
        {types && (
          <Table
            fixedHeader={true}
            customStyles={customStyles}
            columns={columns}
            data={types}
          />
        )}
      </S.Wrapper>
    </>
  )
}

export default AdminStores
