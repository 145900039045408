/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/no-this-in-sfc */
import React from 'react'
import Select from 'react-select'

import { customStyles, Container, Label } from './styles'

type Props = {
  label: string
  labelFor: string
  options: { value: string; label: string }[]
  handleChange(newValue: any): void
}

const DropDown: React.FC<Props> = ({
  options,
  label,
  labelFor,
  handleChange,
}) => {
  return (
    <>
      <Container>
        <Label htmlFor={labelFor}>{label}</Label>
        <Select
          id={labelFor}
          styles={customStyles}
          onChange={handleChange}
          options={options}
        />
      </Container>
    </>
  )
}

export default DropDown
