import React from 'react'
import Header from '../../components/Header'
import useStore from '../../hooks/useStore'
import * as S from './styles'
import Title from '../../components/Title'
import ConfigurationList from '../../components/ConfigurationList'

const Configuration: React.FC = () => {
  const store = useStore()

  return (
    <>
      <Header storeName={store?.vtex_account_name ?? 'Loja'} />
      <S.Wrapper>
        <Title>Configurações</Title>

        <ConfigurationList />
      </S.Wrapper>
    </>
  )
}

export default Configuration
