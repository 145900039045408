import styled from 'styled-components'
import Button from '../../components/Button'

export const Wrapper = styled.main`
  padding: 32px 0;
  max-width: 958px;
  margin: 0 auto;
  width: 100%;
`

export const StyledButton = styled(Button)`
  margin-top: 24px;
  border: none;
  background-color: var(--yellow-500);
  margin-left: 1rem;
  cursor: pointer;
  padding: 10px;
  border-radius: 2px;
`

export const StyledImageUpload = styled.div`
  margin-bottom: 14px;
  display: block;

  input[type='file' i] {
    display: none;
  }

  label {
    background-color: var(--yellow-500);
    border-radius: 4px;
    font-family: Inter;
    font-size: 14px;
    padding: 8px 20px;
    color: white;
    cursor: pointer;
  }

  img {
    max-width: 100%;
    border-radius: 14px;
    margin-top: 18px;
  }
`

export const ImageDisclaimer = styled.span`
  font-size: 12px;
  display: block;
`
