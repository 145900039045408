/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import axios from 'axios'

export const api = axios.create({
  baseURL: 'https://nest-giftlist.maeztra.com',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('app-token')

  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

export const uploadTypewidthImage = async (
  type: string | Blob,
  thumbnailFile: string | Blob
) => {
  const formData = new FormData()
  formData.append('type', type)
  formData.append('thumbnail', thumbnailFile)

  try {
    const response = await api.post('/store/giftlist/types', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}
