import styled from 'styled-components'

export const MenuWrapper = styled.nav`
  padding: 8px 0;
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  list-style: none;
  padding-left: 0;
  margin-left: 0;
`

export const ListItem = styled.li<{ isActive: boolean }>`
  padding: 8px 16px;
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  width: max-content;

  a {
    text-decoration: none;
    color: var(--text-color-500);
  }

  path {
    stroke: #313131;
  }

  ${({ isActive }) =>
    isActive &&
    `
      color: #99800C;
      background: #FFF7D0;
      border-radius: 16px;
      
      a{
        color: #99800C;
      }

      path {
        stroke: #99800C;
      }
  `}
`
