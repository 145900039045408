import styled from 'styled-components'

export const Wrapper = styled.main`
  width: 100%;
  max-width: 958px;
  margin: auto;
  padding: 32px 0;
`

export const Form = styled.form`
  max-width: 600px;
  padding: 33px 40px;
  border: 1px solid #ededed;
  border-radius: 8px;
`

export const Title = styled.h1`
  margin-bottom: 22px;
  margin-top: 70px;
`
