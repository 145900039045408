import React from 'react'
import { useQuery } from 'react-query'
import { api } from '../../services/api'
import * as S from './styles'

type ConfigurationEnum = keyof typeof messages

const messages = {
  orderformOK: (value: boolean) => {
    if (value) {
      return 'Seu Orderform está configurado corretamente'
    }

    return 'Seu Orderform não está configurado corretamente. Adicione o customApp com nome mz-giftlist com as propriedades: store e giftlist'
  },
  feedOK: (value: boolean) => {
    if (value) {
      return 'Seu feed de pedidos está configurado corretamente'
    }

    return 'Seu feed de pedidos ainda não está configurado, verifique novamente mais tarde'
  },
  promotionsOK: (value: boolean) => {
    if (value) {
      return 'Sua promoção de frete grátis está criada'
    }

    return 'Sua promoção de frete grátis não está criada. Crie uma promoção de frete grátis para o cep 99999-991, incluindo todos os produtos'
  },
  listTypesOK: (value: boolean) => {
    if (value) {
      return 'Sua lista já tem pelo menos 1 tipo de lista criado'
    }

    return 'Sua lista ainda não tem nenhum tipo de lista criado'
  },
}

const ConfigurationList: React.FC = () => {
  const { data, isLoading, error } = useQuery('configList', () =>
    api.get('/store/store/config').then((res) => res.data)
  )

  if (isLoading) {
    return <>Loading</>
  }

  if (error) {
    console.error('error: ', error)
  }

  return (
    <S.List>
      {data &&
        Object.keys(data).map((config) => (
          <S.ListItem key={config}>
            {data[config as ConfigurationEnum] ? (
              <S.IconWrapper color="#DAF8E8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20 6L9 17L4 12"
                    stroke="#2B8453"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </S.IconWrapper>
            ) : (
              <S.IconWrapper color="#FDD7D2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10.29 3.8602L1.81999 18.0002C1.64536 18.3026 1.55296 18.6455 1.55198 18.9947C1.551 19.3439 1.64148 19.6873 1.81442 19.9907C1.98735 20.2941 2.23672 20.547 2.5377 20.7241C2.83868 20.9012 3.18079 20.9964 3.52999 21.0002H20.47C20.8192 20.9964 21.1613 20.9012 21.4623 20.7241C21.7633 20.547 22.0126 20.2941 22.1856 19.9907C22.3585 19.6873 22.449 19.3439 22.448 18.9947C22.447 18.6455 22.3546 18.3026 22.18 18.0002L13.71 3.8602C13.5317 3.56631 13.2807 3.32332 12.9812 3.15469C12.6817 2.98605 12.3437 2.89746 12 2.89746C11.6563 2.89746 11.3183 2.98605 11.0188 3.15469C10.7193 3.32332 10.4683 3.56631 10.29 3.8602V3.8602Z"
                    stroke="#922013"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 9V13"
                    stroke="#922013"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 17H12.01"
                    stroke="#922013"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </S.IconWrapper>
            )}
            {messages[config as ConfigurationEnum](data[config])}
          </S.ListItem>
        ))}
    </S.List>
  )
}

export default ConfigurationList
