import React from 'react'

import logo from '../../assets/images/logo.png'

import { Header, Img, Progress, TextContainer, Title, Subtitle } from './styles'

type Props = {
  value: string
  title: string
  subtitle: string
}

const ProgressBar: React.FC<Props> = ({ value, title, subtitle }) => {
  return (
    <Header>
      <Img src={logo} alt="Maeztra" />
      <Progress value={value} max="100" />
      <TextContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TextContainer>
    </Header>
  )
}
export default ProgressBar
