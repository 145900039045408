/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Header from '../../components/Header'
import useStore from '../../hooks/useStore'
import { api } from '../../services/api'
import { TableColumn, TableStyles } from 'react-data-table-component'
import * as S from './styles'
import Button from '../../components/Button'
import { GiftlistTypesType } from '../../typings/giftlistType'
import { toast } from 'react-toastify'
import Table from '../../components/Table'
import TableHeader from '../../components/TableHeader'
import { Modal } from '../../components/Modal'
import Input from '../../components/Input'

const customStyles: TableStyles = {
  header: {
    style: {
      minHeight: '56px',
      borderRadius: '8px 8px 0 0',
    },
  },
  rows: {
    style: {
      borderBottom: '1px solid rgba(0, 0, 0, .12)',
      borderRight: '1px solid rgba(0, 0, 0, .12)',
      borderLeft: '1px solid rgba(0, 0, 0, .12)',
      padding: '16px 32px',
    },
  },
}

const Types: React.FC = () => {
  const store = useStore()
  const [types, setTypes] = useState<GiftlistTypesType[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [reqTrigger, setReqTrigger] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalIsOpenEdit, setModalIsOpenEdit] = useState(false)
  const [listType, setListType] = useState<GiftlistTypesType>()
  const [selectedImage, setSelectedImage] = useState<File>()
  const ref = useRef<HTMLInputElement>(null)
  const imageUrl = selectedImage ? URL.createObjectURL(selectedImage) : null
  const removeType = async (id: string) => {
    try {
      await api.delete(`/store/giftlist/types/${id}`)
      setReqTrigger(!reqTrigger)
    } catch (err) {
      console.log(err)
      toast.error('Houve um erro ao excluir o tipo de lista')
    }
  }
  const changeImageType = async (id: string) => {
    try {
      const listDetails = await api.get(`/store/giftlist/types/${id}`)
      setListType(listDetails.data)
    } catch (err) {
      console.log(err)
      toast.error('Houve um erro!')
    }
  }

  const columns: TableColumn<GiftlistTypesType>[] = useMemo(() => {
    return [
      {
        name: 'Nome e ID',
        cell: (row) => (
          <div>
            <span>{row.type}</span>
            <span>{row.id}</span>
          </div>
        ),
      },
      {
        name: 'Ações',
        cell: (row) => (
          <>
            <Button
              buttonStyle={'danger'}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <g clipPath="url(#clip0_146_3963)">
                    <path d="M2 4.5H3.33333H14" fill="#F33520" />
                    <path
                      d="M2 4.5H3.33333H14"
                      stroke="#F33520"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.33337 4.49992V3.16659C5.33337 2.81296 5.47385 2.47382 5.7239 2.22378C5.97395 1.97373 6.31309 1.83325 6.66671 1.83325H9.33337C9.687 1.83325 10.0261 1.97373 10.2762 2.22378C10.5262 2.47382 10.6667 2.81296 10.6667 3.16659V4.49992M12.6667 4.49992V13.8333C12.6667 14.1869 12.5262 14.526 12.2762 14.7761C12.0261 15.0261 11.687 15.1666 11.3334 15.1666H4.66671C4.31309 15.1666 3.97395 15.0261 3.7239 14.7761C3.47385 14.526 3.33337 14.1869 3.33337 13.8333V4.49992H12.6667Z"
                      stroke="#F33520"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.33337 7.83325V11.8333"
                      stroke="#F33520"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.66663 7.83325V11.8333"
                      stroke="#F33520"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_146_3963">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              }
              style={{ fontSize: '14px', width: 'max-content', padding: 0 }}
              text="Deletar tipo"
              onClick={() => removeType(row.id)}
            />

            <S.StyledButton
              buttonStyle="primary"
              text="Editar"
              onClick={() => {
                changeImageType(row.id)
                setModalIsOpenEdit(true)
              }}
            />
          </>
        ),

        allowOverflow: true,
        button: true,
        width: 'max-content',
      },
    ]
  }, [types])

  useEffect(() => {
    api
      .get<GiftlistTypesType[]>(`/store/giftlist/types`)
      .then((res) => setTypes(res.data))
      .catch(() => toast.error('Erro ao buscar os tipos de lista'))
  }, [reqTrigger])

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setSelectedImage(file)
      }
      reader.readAsDataURL(file)
    }
  }

  const createType = async () => {
    setIsLoading(true)
    const typeValue = ref.current?.value

    if (!typeValue) {
      toast.error('Digite o tipo da lista')
      return
    }

    try {
      const formData = new FormData()
      formData.append('type', typeValue)

      if (selectedImage) {
        formData.append('thumbnail', selectedImage)
      }

      await api.post('/store/giftlist/types', formData)

      setModalIsOpen(false)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(true)
      imageUrl && URL.revokeObjectURL(imageUrl)
    }
  }

  const editType = async () => {
    setIsLoading(true)
    const typeValue = ref.current?.value

    if (!typeValue) {
      toast.error('Digite o tipo da lista')
      return
    }

    try {
      const formData = new FormData()
      formData.append('type', typeValue)

      if (selectedImage) {
        formData.append('thumbnail', selectedImage)
      }

      await api.put(`/store/giftlist/types/${listType?.id}`, formData)

      setModalIsOpenEdit(false)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(true)
      imageUrl && URL.revokeObjectURL(imageUrl)
    }
  }

  return (
    <>
      <Header storeName={store?.vtex_account_name ?? 'Loja'} />
      <S.Wrapper>
        <TableHeader
          createButtonText="Criar tipo"
          title="Tipos de lista"
          onClick={() => setModalIsOpen(true)}
        />
        {types && (
          <Table
            fixedHeader={true}
            customStyles={customStyles}
            columns={columns}
            data={types}
          />
        )}
      </S.Wrapper>
      <Modal
        modalIsOpen={modalIsOpen}
        confirmModalFunction={() => createType()}
        toggleModalFunction={() => setModalIsOpen(false)}
        confirmationText="Salvar"
        title="Criar tipo de lista"
        confirmButtonIsLoading={isLoading}
      >
        <div style={{ maxWidth: '500px', margin: 'auto', width: '100%' }}>
          <Input
            ref={ref}
            type="text"
            labelFor="Tipo"
            labelText="Digite o nome"
            placeholder="Lista de casamento"
          />
          <S.StyledImageUpload>
            <input
              type="file"
              accept="image/*"
              id="imageInput"
              onChange={handleImageChange}
            />
            <label htmlFor="imageInput">Selecionar imagem</label>
            {imageUrl && <img src={imageUrl} alt="Selected" />}
            <S.ImageDisclaimer>
              dimensão recomendada da imagem 318x159px
            </S.ImageDisclaimer>
          </S.StyledImageUpload>
        </div>
      </Modal>
      <Modal
        modalIsOpen={modalIsOpenEdit}
        confirmModalFunction={() => editType()}
        toggleModalFunction={() => setModalIsOpenEdit(false)}
        confirmationText="Salvar"
        title="Editar tipo de lista"
        confirmButtonIsLoading={isLoading}
      >
        <div
          style={{
            maxWidth: '500px',
            margin: 'auto',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <span>{listType?.type}</span>
          <Input
            ref={ref}
            type="text"
            labelFor="Tipo"
            labelText="Digite o nome"
            placeholder={listType?.type || ''}
          />
          <S.StyledImageUpload>
            <input
              type="file"
              accept="image/*"
              id="imageInput"
              onChange={handleImageChange}
            />
            <label htmlFor="imageInput">Selecionar imagem</label>
            {imageUrl && <img src={imageUrl} alt="Selected" />}
          </S.StyledImageUpload>
        </div>
      </Modal>
    </>
  )
}

export default Types
