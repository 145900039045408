import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
#root {
  display: flex;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  :root {
    --yellow-500: #f2c94c;
    --yellow-700: #f5c843;
    --text-color-500: #333333;
    --text-color-300: #555555;
    --text-color-200: #acadad;
    --text-color-100: #ededed;
    --bg-color: #F2F2F2;
    --error-500: #d13434;
    --error-300: #d14141;
    --sucess-500: #219653;
    --sucess-300: #3f9162;
  }
  body {
    margin: 0;
    background-color: #f5f5f5;
    font-family: Inter, -apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }  
  h1 {
    font-size: 32px; 
    color: var(--text-color-500);
  }
  h3 {
    font-size: 28px;
    color: var(--text-color-500);
  }
  h4 {
    font-size: 24px;
    color: var(--text-color-500);
  }
  p {
    font-size: 14px;
    color: var(--text-color-500);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  button,
  a {
    font-family: Inter;
  }
`
