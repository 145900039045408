/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const isAuthenticated = () => {
  const token = localStorage.getItem('app-token')
  // const user = localStorage.getItem('user')
  // const userData = user !== null ? JSON.parse(user) : {}
  if (token) {
    return true
  }
  return false
}
