import styled from 'styled-components'

export const List = styled.ul`
  list-style: none;
  margin-top: 24px;
  padding: 32px;
  background-color: #fff;
  border-radius: 16px;
`

export const ListItem = styled.li`
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const IconWrapper = styled.span<{ color: string }>`
  border-radius: 16px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
`
