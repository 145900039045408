/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Header from '../../components/Header'
import { api } from '../../services/api'
import {
  Count,
  CountValue,
  ErrorContainer,
  ListHeader,
  ProductFilters,
  ListContainer,
  Wrapper,
  // GoBackBtn,
} from '../Giftlist/styles'
import ProgressBar from '../../components/ProgressBar'
import { Subtitle, Title } from '../../components/ProgressBar/styles'
import * as S from './styles'
import Button from '../../components/Button'
import DropDown from '../../components/Select'
import Search from '../../components/Search'
import useStore from '../../hooks/useStore'
import { Modal } from '../../components/Modal'

type ParamsProps = {
  id: string
}

type GiftlistParams = {
  id: number
  is_active: boolean
  store: StoreParams
  event_date: string
  owner: string
  giftcard_id?: number
  profile_image: string
  banner_image: string
  type: {
    type: string
  }
}

type StoreParams = {
  id: number
  is_active: boolean
  vtex_account_name: string
  vtex_key: string
}

const downloadButtonIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
      stroke="#F5F5F5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 10L12 15L17 10"
      stroke="#F5F5F5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15V3"
      stroke="#F5F5F5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Store: React.FC = () => {
  const { id } = useParams<ParamsProps>()
  const [giftlists, setGitftlist] = useState<GiftlistParams[]>([])
  const [accounting, setAccounting] = useState<{
    totalValue: number
    giftlistCount: number
  }>({ totalValue: 0, giftlistCount: 0 })
  const [searchTerm, setSearchTerm] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [error, setError] = useState('')

  const token = localStorage.getItem('app-token')
  const user = localStorage.getItem('user')
  const store = useStore()
  const userData = user !== null ? JSON.parse(user) : {}

  const loadAccountInfo = async () => {
    try {
      const response = await api.get(`/store/store/accounting`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      setAccounting(response.data)
    } catch (err) {
      setError('Falha ao carregar as listas.')
    }
  }

  const downloadSummary = async () => {
    try {
      const response = await api.get(`/store/giftlist/csv`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'giftlist.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (err) {
      setError('Erro ao baixar o resumo')
    }
  }

  const loadGiftlists = async () => {
    try {
      const response = await api.get(
        `/user/giftlist/store/${store?.vtex_account_name}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.data.length > 0) {
        setError('')
        setGitftlist(response.data)
      } else {
        setError('Nenhuma lista cadastrada!')
      }
    } catch (err) {
      setError('Falha ao carregar as listas.')
    }
  }

  const handleDeleteList = async (Id: number) => {
    try {
      const result = await api.delete(`/user/giftlist/disable/${Id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const err = result.data.errors

      if (typeof err === 'string') {
        toast.error(result.data.message)
      } else {
        result.data.message === 'Lista não encontrata.'
          ? toast.error('Apenas o criador da lista pode desativa-la.')
          : toast.success(result.data.message)
      }

      loadGiftlists()
    } catch (err) {
      toast.error('Falha ao desativar.')
    }
  }

  const handleActiveList = async (Id: number) => {
    try {
      const result = await api.post(`/user/giftlist/enable/${Id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const err = result.data.errors

      if (typeof err === 'string') {
        toast.error(result.data.message)
      } else {
        result.data.message === 'Lista não encontrata.'
          ? toast.error('Apenas o criador da lista pode ativa-la.')
          : toast.success(result.data.message)
      }

      loadGiftlists()
    } catch (err) {
      toast.error('Falha ao ativar.')
    }
  }

  const handleOrderBy = (newValue: { label: string; value: string }) => {
    setOrderBy(newValue.value)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    if (store) {
      loadGiftlists()
      loadAccountInfo()
    }
  }, [store])

  const USDollar = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  return (
    <>
      {userData.roles.find((e: string) => e === 'ROLES_STORE') &&
      store?.id !== parseInt(id, 10) ? (
        <>
          <ProgressBar value="100" title="" subtitle="" />
          <ErrorContainer>
            <h1>
              Acesso não autorizado {id} {store?.vtex_account_name}
            </h1>
          </ErrorContainer>
        </>
      ) : (
        <>
          <Header
            storeName={store?.vtex_account_name ? store.vtex_account_name : ''}
          />
          <Wrapper>
            <ListHeader>
              <S.Info>
                <Title>Listas Disponíveis</Title>
                <Subtitle>
                  Veja todas as listas disponíveis na{' '}
                  <span>{store?.vtex_account_name}</span>
                </Subtitle>
              </S.Info>
            </ListHeader>
            <S.AccountInfo>
              <S.AccountInfoCard>
                <S.AccountInfoCardTitle>
                  Faturamento das listas
                </S.AccountInfoCardTitle>
                <S.AccountInfoCardValue>
                  {USDollar.format(accounting.totalValue / 100)}
                </S.AccountInfoCardValue>
              </S.AccountInfoCard>
              <S.AccountInfoCard>
                <S.AccountInfoCardTitle>Listas criadas</S.AccountInfoCardTitle>
                <S.AccountInfoCardValue>
                  {accounting.giftlistCount}
                </S.AccountInfoCardValue>
              </S.AccountInfoCard>
              <Button
                buttonStyle="primary"
                text="Baixar resumo"
                icon={downloadButtonIcon}
                onClick={() => downloadSummary()}
              />
            </S.AccountInfo>
            <ProductFilters>
              <Count>
                <CountValue>
                  {giftlists.length}
                  &nbsp;
                </CountValue>
                listas encontradas:
              </Count>
              <DropDown
                label="Ordenar por:"
                labelFor="orderBy"
                options={[
                  { label: 'Ordem Padrão', value: 'default' },
                  { label: 'ID - Decrescente', value: 'id' },
                ]}
                handleChange={handleOrderBy}
              />
              <Search placeholder="Pesquisar" onChange={handleChange} />
            </ProductFilters>
            <ListContainer>
              <S.GiftlistTableHeader>
                <S.TableHeaderItem>Nome e ID</S.TableHeaderItem>
                <S.TableHeaderItem>Status / Tipo</S.TableHeaderItem>
                <S.TableHeaderItem>Ações</S.TableHeaderItem>
              </S.GiftlistTableHeader>
              {error ? (
                <ErrorContainer>
                  <p style={{ margin: 10 }}>{error}</p>
                </ErrorContainer>
              ) : (
                giftlists
                  .filter((giftlist: GiftlistParams) => {
                    if (searchTerm === '') {
                      return giftlist
                    }
                    if (
                      giftlist.owner
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      giftlist.id === parseInt(searchTerm, 10)
                    ) {
                      return giftlist
                    }
                  })
                  .filter((giftlist: GiftlistParams) => {
                    if (orderBy === 'id') {
                      return giftlists?.sort((a, b) => {
                        return a.id < b.id ? 1 : b.id < a.id ? -1 : 0
                      })
                    }
                    if (orderBy === 'default') {
                      return giftlists?.sort((a, b) => {
                        return a.id > b.id ? 1 : b.id > a.id ? -1 : 0
                      })
                    }
                    return giftlist
                  })
                  .map((giftlist: GiftlistParams) => {
                    return (
                      <>
                        <S.Giftlist>
                          <td>
                            <S.GiftlistName>
                              <S.ProfileImage src={giftlist.profile_image} />
                              <div>
                                <p>
                                  <strong>{giftlist.owner}</strong>
                                </p>
                                <p>#{giftlist.id}</p>
                              </div>
                            </S.GiftlistName>
                          </td>
                          <td>
                            <S.GiftlistInfo>
                              <S.ActiveLabel isActive={!!giftlist.is_active}>
                                {giftlist.is_active ? 'Ativa' : 'Inativa'}
                              </S.ActiveLabel>
                              <S.GiftlistTypeLabel>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_156_450)">
                                    <path
                                      d="M10 6V11H2V6"
                                      stroke="#494949"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M11 3.5H1V6H11V3.5Z"
                                      stroke="#494949"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6 11V3.5"
                                      stroke="#494949"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6 3.5H8.25C8.58152 3.5 8.89946 3.3683 9.13388 3.13388C9.3683 2.89946 9.5 2.58152 9.5 2.25C9.5 1.91848 9.3683 1.60054 9.13388 1.36612C8.89946 1.1317 8.58152 1 8.25 1C6.5 1 6 3.5 6 3.5Z"
                                      stroke="#494949"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6 3.5H3.75C3.41848 3.5 3.10054 3.3683 2.86612 3.13388C2.6317 2.89946 2.5 2.58152 2.5 2.25C2.5 1.91848 2.6317 1.60054 2.86612 1.36612C3.10054 1.1317 3.41848 1 3.75 1C5.5 1 6 3.5 6 3.5Z"
                                      stroke="#494949"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_156_450">
                                      <rect
                                        width="12"
                                        height="12"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                {giftlist.type.type}
                              </S.GiftlistTypeLabel>
                            </S.GiftlistInfo>
                          </td>
                          <td>
                            <S.Actions>
                              <Link
                                to={`/giftlist/${giftlist.id}`}
                                style={{
                                  textDecoration: 'none',
                                  width: 'max-content',
                                  display: 'flex',
                                  justifyContent: 'right',
                                }}
                              >
                                <Button
                                  buttonStyle="secondary"
                                  text="Ver detalhes"
                                  isLoading={false}
                                  onClick={() => {
                                    setModalIsOpen(true)
                                  }}
                                  style={{
                                    fontSize: '14px',
                                    width: '100%',
                                    padding: '8px 16px',
                                  }}
                                />{' '}
                              </Link>
                              {giftlist.is_active ? (
                                <Button
                                  buttonStyle="transparent"
                                  text="Desativar lista"
                                  isLoading={false}
                                  onClick={() => handleDeleteList(giftlist.id)}
                                  style={{
                                    width: 'max-content',
                                    fontSize: '14px',
                                    padding: '8px 16px',
                                  }}
                                />
                              ) : (
                                <Button
                                  buttonStyle="transparent"
                                  text="Ativar lista"
                                  isLoading={false}
                                  onClick={() => handleActiveList(giftlist.id)}
                                  style={{ width: '48%', fontSize: '12px' }}
                                />
                              )}
                            </S.Actions>
                          </td>
                        </S.Giftlist>
                      </>
                    )
                  })
              )}
            </ListContainer>
          </Wrapper>
        </>
      )}
      <Modal
        modalIsOpen={modalIsOpen}
        toggleModalFunction={() => setModalIsOpen(false)}
        confirmModalFunction={() => setModalIsOpen(false)}
        title="Criar tipo de lista"
      >
        <div
          style={{
            maxWidth: '500px',
            margin: 'auto',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <h1>Lista</h1>
        </div>
      </Modal>
    </>
  )
}
export default Store
