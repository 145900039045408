import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Img = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 26px auto;
`

export const Progress = styled.progress`
  background-color: var(--text-color-100);
  border: 0;
  height: 4px;
  width: 80vw;
  &::-webkit-progress-bar {
    background-color:var(--text-color-100, grey);
  }
  &::-webkit-progress-value {
    background-color: var(--yellow-500, yellow);
  }
  &::-moz-progress-bar {
    background-color: var(--yellow-500, yellow);
  }
}
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 35px 0;
`

export const Title = styled.h2`
  margin-bottom: 16px;
`

export const Subtitle = styled.p`
  color: var(--text-color-300);
`
