import React from 'react'
import LogoImg from '../../assets/images/logo-placeholder.png'

import * as S from './styles'
import Menu from '../Menu'
import { User } from '../../typings/user'

type Props = {
  storeName: string
}

const Header: React.FC<Props> = ({ ...rest }) => {
  const userString = localStorage.getItem('user')
  const user: User = userString !== null && JSON.parse(userString)

  return (
    <>
      <S.StyledHeader {...rest}>
        <S.Logo src={LogoImg} alt="Maeztra" />
        <Menu type={user.roles.includes('ROLES_ADMIN') ? 'Admin' : 'Store'} />
      </S.StyledHeader>
    </>
  )
}

export default Header
