/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react'
// import { useParams } from 'react-router-dom'

// import { FaTrashAlt } from 'react-icons/fa'
// import { toast } from 'react-toastify'
import { Modal } from '../Modal'

import {
  Wrapper,
  Div,
  Img,
  ProductName,
  // ProductLink,
  // ProductStatus,
  // Quantity,
  // ValueBtn,
  // InputNumber,
} from './styles'

// import Button from '../Button'

// import { api } from '../../services/api'

type ProductProps = {
  listId: number
  product: any
  handleDelete: any
}

// type ParamsProps = {
//   id: string
// }

const Product: React.FC<ProductProps> = ({ listId, product, handleDelete }) => {
  // const [quantity, setQuantity] = useState(product.quantity)
  const [showModal, setShowModal] = useState(false)
  console.log('hei')
  console.log(product)
  // const [loading, setLoading] = useState(false)
  // console.log(product)
  // const { id } = useParams<ParamsProps>()
  // const token = localStorage.getItem('app-token')

  const handleDeleteList = async (Id: number, VtexId: string) => {
    handleDelete(Id, VtexId)
    setShowModal(!showModal)
  }

  // const handleQuantityChange = async (value: number) => {
  //   try {
  //     setQuantity(value)
  //     setLoading(true)
  //     await api
  //       .post(
  //         `/user/giftlist/${id}/product/add/`,
  //         {
  //           vtex_id: product.skuId,
  //           quantity: value,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then(() => {
  //         setLoading(false)
  //       })
  //   } catch (err) {
  //     toast.error('Erro ao tentar alterar a quantidade!')
  //     setLoading(false)
  //   }
  // }
  console.log(product[0])
  return (
    <>
      {product.length > 0 && product[0]?.items && (
        <>
          <Wrapper>
            <Div>
              <Img
                src={product[0]?.items[0]?.images[0]?.imageUrl || ''}
                alt={product[0]?.productName}
              />
              <div>
                <ProductName>{product[0]?.productName}</ProductName>
              </div>
            </Div>
            {/* {quantity < 0 ? (
      <ProductStatus>produto comprado</ProductStatus>
    ) : (
      <Quantity>
        <ValueBtn
          name="minus"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            if (!loading) {
              if (quantity > 1) {
                handleQuantityChange(quantity - 1)
              } else {
                setShowModal(true)
              }
            }
          }}
        >
          <FaMinus />
        </ValueBtn>
        <InputNumber type="number" name="quantity" value={quantity} />
        <ValueBtn
          name="plus"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            if (!loading) {
              handleQuantityChange(quantity + 1)
            }
          }}
        >
          <FaPlus />
        </ValueBtn>
      </Quantity>
    )} */}
          </Wrapper>
          <Modal
            modalIsOpen={showModal}
            toggleModalFunction={() => {
              setShowModal(!showModal)
            }}
            confirmModalFunction={() => {
              handleDeleteList(listId, product.skuId)
            }}
          >
            <h1>Tem certeza?</h1>
            <p>Se apagar não voltará a ver esse conteúdo!</p>
          </Modal>
        </>
      )}
    </>
  )
}

export default Product
