import styled from 'styled-components'
// import { Link } from 'react-router-dom'

export const ListHeader = styled.div``
export const Info = styled.div``
export const Giftlist = styled.tr`
  background-color: #fff;
  border-top: 2px solid #f5f5f5;
`
export const GiftlistName = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const GiftlistInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  vertical-align: baseline;
`
export const Span = styled.span`
  font-size: 12px;
  color: var(--yellow-500);
`

export const LinksWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const ProfileImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 16px;
  border: 2px solid #fff7d0;
`

export const GiftlistTableHeader = styled.tr``

export const TableHeaderItem = styled.th`
  text-align: left;
  padding: 12px 16px 4px;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  width: 30%;
  color: #7a7a7a;
  font-weight: 400;
`

export const ActiveLabel = styled.span<{ isActive: boolean }>`
  padding: 4px 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  border-radius: 8px;
  background-color: ${({ isActive }) => (isActive ? '#DAF8E8' : '#FFECD0')};
`

export const GiftlistTypeLabel = styled.span`
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  border-radius: 8px;
  text-transform: uppercase;
  background-color: #f5f5f5;
  display: flex;
  gap: 4px;
  align-items: center;
`

export const AccountInfo = styled.div`
  border-radius: 16px;
  background: linear-gradient(135deg, #ffe672 0%, #ffd614 100%);
  padding: 32px;
  display: flex;
  align-items: flex-end;
  gap: 16px;
  button {
    min-width: 195px;
  }
`

export const AccountInfoCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 4px;
  width: 50%;
`

export const AccountInfoCardTitle = styled.p`
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  color: #626262;
`

export const AccountInfoCardValue = styled.span`
  display: block;
  font-size: 24px;
  line-height: 1.15;
  color: #332b04;
  font-weight: 700;
`
