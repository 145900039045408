import styled from 'styled-components'
import { Link } from 'react-router-dom'

import bgImg from '../../assets/images/vector.svg'

export const Container = styled.div`
  grid-gap: 20px;
  display: flex;
  max-width: 648px;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
  @media (min-width: 700px) {
    grid-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
  }
`
export const Store = styled(Link)`
  width: calc(50vw - 20px);
  height: 72px;
  border-radius: 5px;
  background-size: 170%;
  background-color: var(--text-color-500);
  background-image: url(${bgImg});
  background-position: 90% 5%;

  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 700px) {
    width: 314px;
    height: 144px;
    background-size: auto;
    border-radius: 12px;
    font-size: 20px;
  }
`

export const ErrorContainer = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 10px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ErrorMessage = styled.h1`
  color: var(--text-color-500);
  margin: 10px;
`
