/* eslint-disable camelcase */
import React, { ReactNode } from 'react'

import * as S from './styles'
import Button from '../Button'

type ModalProps = {
  modalIsOpen: boolean
  toggleModalFunction: () => void
  confirmModalFunction: () => void
  children: ReactNode
  confirmationText?: string
  title?: string
  confirmButtonIsLoading?: boolean
}

export const Modal: React.FC<ModalProps> = ({
  modalIsOpen,
  toggleModalFunction,
  confirmModalFunction,
  confirmationText = 'Continuar',
  children,
  title,
  confirmButtonIsLoading = false,
}) => (
  <S.Container modalIsOpen={modalIsOpen}>
    <S.ModalContent>
      <S.ModalHeader>
        {title && <S.Title>{title}</S.Title>}
        <S.CloseButton onClick={toggleModalFunction}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4.29292 18.2931L10.5859 12.0001L4.29292 5.70708C4.11076 5.51848 4.00997 5.26588 4.01224 5.00368C4.01452 4.74148 4.11969 4.49067 4.3051 4.30526C4.49051 4.11985 4.74132 4.01469 5.00352 4.01241C5.26571 4.01013 5.51832 4.11092 5.70692 4.29308L11.9999 10.5861L18.2929 4.29308C18.3852 4.19757 18.4955 4.12139 18.6175 4.06898C18.7395 4.01657 18.8707 3.98898 19.0035 3.98783C19.1363 3.98668 19.268 4.01198 19.3909 4.06226C19.5138 4.11254 19.6254 4.18679 19.7193 4.28069C19.8132 4.37458 19.8875 4.48623 19.9377 4.60913C19.988 4.73202 20.0133 4.8637 20.0122 4.99648C20.011 5.12926 19.9834 5.26048 19.931 5.38249C19.8786 5.50449 19.8024 5.61483 19.7069 5.70708L13.4139 12.0001L19.7069 18.2931C19.8024 18.3853 19.8786 18.4957 19.931 18.6177C19.9834 18.7397 20.011 18.8709 20.0122 19.0037C20.0133 19.1365 19.988 19.2681 19.9377 19.391C19.8875 19.5139 19.8132 19.6256 19.7193 19.7195C19.6254 19.8134 19.5138 19.8876 19.3909 19.9379C19.268 19.9882 19.1363 20.0135 19.0035 20.0123C18.8707 20.0112 18.7395 19.9836 18.6175 19.9312C18.4955 19.8788 18.3852 19.8026 18.2929 19.7071L11.9999 13.4141L5.70692 19.7071C5.51832 19.8892 5.26571 19.99 5.00352 19.9878C4.74132 19.9855 4.49051 19.8803 4.3051 19.6949C4.11969 19.5095 4.01452 19.2587 4.01224 18.9965C4.00997 18.7343 4.11076 18.4817 4.29292 18.2931Z"
              fill="#313131"
              stroke="#313131"
            />
          </svg>
        </S.CloseButton>
      </S.ModalHeader>
      <S.TextArea>{children}</S.TextArea>
      <S.Actions>
        <Button
          onClick={toggleModalFunction}
          text="Cancelar"
          buttonStyle="transparent"
        />
        <Button
          onClick={confirmModalFunction}
          text={confirmationText}
          buttonStyle="primary"
          isLoading={confirmButtonIsLoading}
        />
      </S.Actions>
    </S.ModalContent>
  </S.Container>
)
