/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import Header from '../../components/Header'
import { api } from '../../services/api'
import { TableColumn, TableStyles } from 'react-data-table-component'
import * as S from './styles'
import Button from '../../components/Button'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { User } from '../../typings/user'
import TableHeader from '../../components/TableHeader'
import Table from '../../components/Table'

const customStyles: TableStyles = {
  header: {
    style: {
      minHeight: '56px',
    },
  },
  rows: {
    style: {
      borderBottom: '1px solid rgba(0, 0, 0, .12)',
      borderRight: '1px solid rgba(0, 0, 0, .12)',
      borderLeft: '1px solid rgba(0, 0, 0, .12)',
    },
  },
}

const AdminUsers: React.FC = () => {
  const [types, setTypes] = useState<User[]>()
  const [reqTrigger, setReqTrigger] = useState(false)

  const removeUser = async (id: number) => {
    try {
      await api.delete(`/admin/user/${id}`)
      setReqTrigger(!reqTrigger)
    } catch (err) {
      console.log(err)
      toast.error('Houve um erro ao desabilitar a usuário')
    }
  }

  const columns: TableColumn<User>[] = useMemo(() => {
    return [
      {
        name: 'Id',
        selector: (row) => row.id,
      },
      {
        name: 'Name',
        selector: (row) => row.name,
      },
      {
        name: 'Account Name',
        selector: (row) => row.store.vtex_account_name,
      },
      {
        name: 'Ações',
        cell: (row) => (
          <>
            <Link
              to={`/admin/user/update/${row.id}`}
              style={{
                display: 'block',
                textDecoration: 'none',
                width: 'fit-content',
                marginRight: '8px',
              }}
            >
              <Button text="Editar" buttonStyle="secondary" />
            </Link>
            <Button
              buttonStyle={'danger'}
              text={'Remover'}
              onClick={() => removeUser(row.id)}
              style={{ width: '110px' }}
            />
          </>
        ),
        allowOverflow: true,
        button: true,
        width: '200px',
      },
    ]
  }, [types])

  useEffect(() => {
    api
      .get<User[]>(`/admin/user`)
      .then((res) => setTypes(res.data))
      .catch(() => toast.error('Erro ao buscar os usuários'))
  }, [reqTrigger])

  return (
    <>
      <Header storeName="Admin" />
      <S.Wrapper>
        <TableHeader title="Usuários" hrefButton="/admin/users/create" />
        {types && (
          <Table
            fixedHeader={true}
            customStyles={customStyles}
            columns={columns}
            data={types}
          />
        )}
      </S.Wrapper>
    </>
  )
}

export default AdminUsers
